import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

interface ErrorMessage {
  title: string;
  description: string;
}

const ERROR_CODES = {
  INVALID_TOKEN: '403',
  INVALID_TOKEN_2: '190',
  INVALID_TOKEN_3: '401',
  NOT_FOUND: 'NOT_FOUND',
  NO_POSTS: '405',
  GENERAL: '407',
  NO_POSTS_YET: 'NO_POSTS_YET',
};

const ERRORS_MESSAGE = {
  PASSWORD_CHANGED:
    'The session has been invalidated because the user changed their password',
  SESSION_EXPIRED: 'Session has expired on',
  NOT_CONFIRMED_USER:
    'Sessions for the user are not allowed because the user is not a confirmed user.',
  NOT_AUTHORIZED_APP: 'The user has not authorized application',
  NO_PERMISSION: 'Application does not have permission for this action',
  INVALID_AUTH: 'Invalid OAuth access token',
  FETCHED_FROM_CACHE: 'Fetched from cache',
  ENTITY_NOT_FOUND: 'Entity not found',
};

export const generateErrorMessage = (
  error,
  t: EditorScriptFlowAPI['translations']['t'],
): ErrorMessage => {
  try {
    const { title, description } = errorMapper(error);
    return { title: t(title), description: t(description) };
  } catch (err) {
    console.error('[generateErrorMessage]', err);
    return {
      title: t('instagram-blocks.widget.emptyState.default.title'),
      description: t('instagram-blocks.widget.emptyState.default.description'),
    };
  }
};

const errorMapper = (error): ErrorMessage => {
  const { code, description } = error.details.applicationError;
  const message = error.message || description;
  const codeKey = getCodeKey({ code, message });
  console.log('Error Mapper', { error, codeKey, message });

  return {
    title: `instagram-blocks.widget.emptyState.${codeKey}.title`,
    description: `instagram-blocks.widget.emptyState.${codeKey}.description`,
  };
};

const getCodeKey = ({ code, message }): (typeof TranslationStrings)[number] => {
  let codeKey = 'default' as (typeof TranslationStrings)[number];
  switch (code) {
    case ERROR_CODES.NO_POSTS_YET:
      codeKey = 'noPostsYet';
      break;
    case ERROR_CODES.NOT_FOUND:
      if (message === ERRORS_MESSAGE.ENTITY_NOT_FOUND) {
        codeKey = 'entityNotFound';
      }
      break;
    case ERROR_CODES.INVALID_TOKEN:
    case ERROR_CODES.INVALID_TOKEN_2:
    case ERROR_CODES.INVALID_TOKEN_3:
      if (message.includes(ERRORS_MESSAGE.PASSWORD_CHANGED)) {
        codeKey = 'passwordChange';
      } else if (message.includes(ERRORS_MESSAGE.SESSION_EXPIRED)) {
        codeKey = 'sessionExpired';
      } else if (message.includes(ERRORS_MESSAGE.NOT_CONFIRMED_USER)) {
        codeKey = 'notConfirmedUser';
      } else if (message.includes(ERRORS_MESSAGE.NOT_AUTHORIZED_APP)) {
        codeKey = 'notAuthorizedApp';
      } else if (message.includes(ERRORS_MESSAGE.NO_PERMISSION)) {
        codeKey = 'missingPermissions';
      } else if (message.includes(ERRORS_MESSAGE.INVALID_AUTH)) {
        codeKey = 'invalidAuth';
      }
      break;
  }

  return codeKey;
};

const TranslationStrings = [
  'default',
  'passwordChange',
  'apiIssue', // Not assigned
  'accountDisconnected', // Not assigned
  'missingPermissions',
  'entityNotFound',
  'sessionExpired',
  'notConfirmedUser',
  'invalidAuth',
  'notAuthorizedApp',
  'invalidResponse', // Not assigned
  'noPostsYet',
] as const;

// Generic Error *
// MissingPermissions *
// PasswordChanged *
// MetaIssue
// siteInactive (AccountDisconnected)
// === OLD APP
// Session Expired *
// AccountDisconnected 1(unconfirmedUser) *
// AccountDisconnected 1(invalidAuthentcation) *
// Connection Problem (nauthorized App) *
// No permissions (Technical Issue)
// Loading Error (API Profile)
