import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import {
  ListInstagramAccountMediaResponse,
  Media,
} from '@wix/ambassador-instagram-feed-v1-instagram-media/types';
import { userMediasStore as initialStore } from './fetchConnectedUserData';
import { ElementRoles } from '../../enums';
import { convertMediasToPG } from './convertMediasToPG';
import { LightboxDataI, WidgetPropsI } from '../../types';
import { igApi } from '../../api';

let currentStore: ListInstagramAccountMediaResponse = {};

export const onLoadMoreHandler = debounce(
  async (
    $w,
    flowAPI: PlatformControllerFlowAPI,
    lightboxData: LightboxDataI,
    props: WidgetPropsI,
  ) => {
    const { paginationInstance } = lightboxData;
    const { mediasOnLoadMore, accountId, mediasOnInitialLoad } = props;
    const onInit = !Object.keys(currentStore).length;
    const itemsStore = onInit ? initialStore : currentStore;

    const restOfItems = onInit
      ? [
          ...(cloneDeep(
            itemsStore.media?.slice(mediasOnInitialLoad),
          ) as Media[]),
        ]
      : (itemsStore.media as Media[]);

    const [itemsToAdd, itemsLeft] = [
      { media: restOfItems.slice(0, mediasOnLoadMore) },
      {
        paging: itemsStore.paging,
        media: restOfItems.slice(mediasOnLoadMore),
      },
    ] as const;

    const pgMediasApi = convertMediasToPG(itemsToAdd);
    $w(`#${ElementRoles.ProGallery}`).addItems(pgMediasApi);

    const shouldFetch = (itemsLeft.media?.length as number) < mediasOnLoadMore;

    if (shouldFetch) {
      const { instance } = flowAPI.controllerConfig.appParams;
      const mediasApi = await igApi(instance).getMedias(accountId, {
        paginationInstance,
      });

      const pgMediasApi = convertMediasToPG(mediasApi);
      lightboxData.media = [...lightboxData.media, ...pgMediasApi];

      currentStore = {
        ...mediasApi,
        media: [
          ...(itemsLeft.media as Media[]),
          ...(mediasApi.media as Media[]),
        ],
      };
    } else {
      currentStore = { ...currentStore, media: itemsLeft.media };
    }
  },
  300,
);

let demoCounter = 0;

export const onLoadMoreDemoHandler = (
  $w: any,
  lightboxData: LightboxDataI,
  props: WidgetPropsI,
): void => {
  const { mediasOnLoadMore, mediasOnInitialLoad } = props;

  if (!demoCounter) {
    demoCounter = mediasOnInitialLoad;
  }
  const allDemoMedias = cloneDeep(lightboxData.media);
  const itemsToAdd = allDemoMedias.slice(
    demoCounter,
    demoCounter + mediasOnLoadMore,
  );
  demoCounter += mediasOnLoadMore;
  const mediasEnd = demoCounter >= allDemoMedias.length;

  if (mediasEnd) {
    $w(`#${ElementRoles.LoadMore}`).collapse();
  }
  $w(`#${ElementRoles.ProGallery}`).addItems(itemsToAdd);
};
