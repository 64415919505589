import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import cloneDeep from 'lodash/cloneDeep';
import { ElementRoles, PgCustomOnclick } from '../../enums';
import { selectorFactory } from './selectorFactory';
import { igApi } from '../../api';
import { FULL_WIDGET_CONTROLLER_ID } from '../../constants';
import { convertMediasToPG } from './convertMediasToPG';
import { LightboxDataI, WidgetPropsI } from '../../types';
import { onLoadMoreDemoHandler, onLoadMoreHandler } from './onLoadMoreHandler';

type ListenerArgs = [
  pgWidgetType: any,
  flowAPI: PlatformControllerFlowAPI,
  $w: any,
  props: WidgetPropsI,
];

let lightboxData = {} as LightboxDataI;

export const addEventListeners = (
  flowAPI: PlatformControllerFlowAPI,
  $w,
  props: WidgetPropsI,
  addedLightboxData?: LightboxDataI,
) => {
  const { name } = flowAPI.controllerConfig;
  const { accountId, shouldLimitImages, showLoadMore } = props;
  const isMainWidget = FULL_WIDGET_CONTROLLER_ID === name;
  const proGalleryWidget = isMainWidget
    ? $w(`#${ElementRoles.ProGallery}`)
    : $w(`#${ElementRoles.ExpandProGallery}`);

  lightboxData = cloneDeep({...addedLightboxData, props }) as LightboxDataI;
  const args = [proGalleryWidget, flowAPI, $w, props] as ListenerArgs;

  // On click item
  onItemClickedListener(...args);
  // Get more items (scroll)
  if (isMainWidget && !shouldLimitImages && !showLoadMore && accountId) {
    onGetMoreItemsListener(...args);
  }
  // on loadMore click
  if (showLoadMore) {
    onClickLoadMore(...args);
  }
};

const onItemClickedListener = (...args: ListenerArgs) => {
  const [pgWidget, _, $w, props] = args;
  const { onClickAction } = props;
  pgWidget.onItemClicked((pgItem) => {
    switch (onClickAction) {
      case PgCustomOnclick.OpenExpand:
        const { openExpandMode } = selectorFactory($w);
        openExpandMode({ pgItem, data: lightboxData });
        break;
      case PgCustomOnclick.OpenLink:
        pgWidget.manualStyleParams = {
          behaviourParams_item_clickAction: 'LINK',
        };
    }
  });
};

const onClickLoadMore = (...args: ListenerArgs) => {
  const [_, flowAPI, $w, props] = args;
  $w(`#${ElementRoles.LoadMore}`).onClick(async () => {
    props.accountId
      ? onLoadMoreHandler($w, flowAPI, lightboxData, props)
      : onLoadMoreDemoHandler($w, lightboxData, props);
  });
};

const onGetMoreItemsListener = (...args: ListenerArgs) => {
  const [pgWidget, flowAPI, _, props] = args;
  const { paginationInstance } = lightboxData;
  pgWidget.onGetMoreItems(async () => {
    const { instance } = flowAPI.controllerConfig.appParams;
    const mediasApi = await igApi(instance).getMedias(props.accountId, {
      paginationInstance,
    });
    const pgMediasApi = convertMediasToPG(mediasApi);
    pgWidget.addItems(pgMediasApi);
    lightboxData.media = [...lightboxData.media, ...pgMediasApi];
  });
};
