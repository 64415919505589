import { LoadingPropsI, LoadingPropsI_m } from '../types';
import { GRID_LIMIT } from './presets';

export const gridPropsOverride: LoadingPropsI_m & LoadingPropsI = {
  mediasOnInitialLoad: GRID_LIMIT,
  mediasOnInitialLoad_m: GRID_LIMIT,
  mediasOnLoadMore: GRID_LIMIT,
  mediasOnLoadMore_m: GRID_LIMIT,
  shouldLimitImages: true,
  shouldLimitImages_m: true,
  showLoadMore: false,
  showLoadMore_m: false,
};
