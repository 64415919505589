import { gridPropsOverride } from '../../constants';
import { PresetNames } from '../../enums';
import { WidgetPropsI } from '../../types';

export const overrideWithMobileProps = (
  props: WidgetPropsI,
  isMobile: boolean,
): WidgetPropsI => {
  const {
    showLoadMore_m,
    mediasOnInitialLoad_m,
    mediasOnLoadMore_m,
    shouldLimitImages_m,
    ...restOfProps
  } = props;
  const updatedProps = { ...restOfProps } as WidgetPropsI;

  if (isMobile) {
    updatedProps.mediasOnInitialLoad = mediasOnInitialLoad_m;
    updatedProps.showLoadMore = showLoadMore_m;
    updatedProps.shouldLimitImages = shouldLimitImages_m;
    updatedProps.mediasOnLoadMore = mediasOnLoadMore_m;
  }

  return updatedProps;
};

export const getGridOverride = (
  widgetProps: WidgetPropsI,
  isMobile: boolean,
) => {
  const isGrid =
    ((widgetProps.preset === PresetNames.Grid && !isMobile) ||
      (widgetProps.mobilePreset === PresetNames.Grid_M && isMobile)) &&
      !widgetProps.shouldLimitImages;
  return isGrid ? gridPropsOverride : {};
};
