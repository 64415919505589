import {
  MediaType,
  Children,
  ListInstagramAccountMediaResponse,
} from '@wix/ambassador-instagram-feed-v1-instagram-media/types';

export interface PgItemType {
  type: 'image' | 'video' | 'carousel';
  description: string;
  alt: string;
  src: string;
  thumbnail?: string;
  link: string;
  timestamp: Date;
  children?: any[];
}

export const convertMediasToPG = (
  instaMedia,
  isChildren: boolean = false,
): PgItemType[] => {
  isChildren
    ? (instaMedia as Children[])
    : (instaMedia as ListInstagramAccountMediaResponse);
  instaMedia = isChildren ? instaMedia : instaMedia.media;
  return instaMedia?.map((item?) => {
    const {
      timestamp,
      mediaType,
      caption: description,
      permalink: link,
      mediaUrl,
      thumbnailUrl,
    } = item;

    switch (mediaType as MediaType) {
      case MediaType.IMAGE:
        return {
          type: 'image',
          description,
          alt: description,
          src: mediaUrl,
          link,
          timestamp,
        } as PgItemType;
      case MediaType.VIDEO:
        return {
          type: 'video',
          description,
          alt: description,
          src: mediaUrl,
          thumbnail: thumbnailUrl,
          link,
          timestamp,
        } as PgItemType;
      case MediaType.CAROUSEL_ALBUM:
        const children = convertMediasToPG(item?.children, true);
        return {
          type: 'carousel',
          description,
          alt: description,
          src: mediaUrl,
          link,
          timestamp,
          children,
        } as PgItemType;
      case MediaType.UNKNOWN:
      default:
        return {} as PgItemType;
    }
  });
};

// Create a carousel from every media type so user will see the arrows to customize
export const createCarousel = (media: PgItemType): PgItemType => {
  if (media.type !== 'carousel') {
    const albumImageSrc = media.type === 'image' ? media.src : media.thumbnail;
    media.children = [
      { type: 'image', src: albumImageSrc },
      { type: 'image', src: albumImageSrc },
    ];
    media.type = 'carousel';
  }
  return media;
};
